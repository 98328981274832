import {JsonProperty, JsonType} from 'ta-json';
import {WorkdaySegment} from './workday-segment';

export class WorkdaySlot {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('slot_id')
    @JsonType(Number)
    slot_id: number = null;

    @JsonProperty('date')
    @JsonType(Date)
    date: Date = null;

    @JsonProperty('flag_workload_calculation')
    @JsonType(Boolean)
    flag_workload_calculation: boolean = false;

    @JsonProperty('segments')
    @JsonType(WorkdaySegment)
    segments: WorkdaySegment[] = [];
}
