import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {User} from '../../../models/user';
import {PositionShortSlot} from './position-short-slot';
import {Rating} from '../../shared/models/rating';
import {SubdivisionShortSlot} from './subdivision-short-slot';
import {WorkdaySnapshot} from './workday-snapshot';

export class Slot {
    @JsonProperty('id')
    @JsonType(Number)
    id?: number;

    @JsonProperty('is_role')
    @JsonType(Boolean)
    is_role = false;

    @JsonProperty('is_active')
    @JsonType(Boolean)
    is_active = false;

    @JsonProperty('role')
    @JsonType(String)
    role: string = 'employee' || 'manager';

    @JsonProperty('user')
    @JsonElementType(User)
    user: User = null;

    @JsonProperty('position')
    @JsonElementType(PositionShortSlot)
    position: PositionShortSlot = null;

    @JsonProperty('struct')
    @JsonElementType(SubdivisionShortSlot)
    struct: SubdivisionShortSlot = null;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating = null;

    @JsonProperty('rating_top')
    @JsonElementType(Rating)
    rating_top: Rating = null;

    @JsonProperty('workday_snapshot')
    @JsonElementType(WorkdaySnapshot)
    workday_snapshot: WorkdaySnapshot;

    // Кастомное свойство для карточек в компании
    functionRole = null;

    constructor(
        is_role: boolean,
        role: string,
        position?: PositionShortSlot,
    ) {
        this.is_role = is_role;
        this.role = role;
        this.position = position;
    }
}
