
<div class="status _icon"
     [ngClass]="{'_icon': !isTextStatus, '_txt': isTextStatus}">
    <mat-icon *ngIf="!isTextStatus && isSvgIcon"
              class="icon"
              [ngClass]="task && task.status ? task.status.color : ''"
              [matTooltip]="taskStatusName"
              [svgIcon]="taskStatusIcon">
    </mat-icon>

    <mat-icon *ngIf="!isTextStatus && !isSvgIcon"
              class="icon"
              [ngClass]="task && task.status ? task.status.color : ''"
              [matTooltip]="taskStatusName">
        {{ taskStatusIcon }}
    </mat-icon>

    <span *ngIf="isTextStatus && task && task.status"
          class="txt"
          [matTooltip]="taskStatusName"
          [ngClass]="task.status.color">
        {{ taskStatusName }}
    </span>
</div>
