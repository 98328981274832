import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserShort} from '../../../../models/user';
import {ConfigBoardsService} from '../../../task-manager/services/config-boards.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss']
})
export class UserListItemComponent implements OnInit {

    @Output() onRemove = new EventEmitter<UserShort>();
    @Output() onUser = new EventEmitter<UserShort>();
    @Output() positionClick = new EventEmitter();
    @Input() public userSubdivision: UserShort = null;
    @Input() public shortView = false;
    @Input() public removable = false;
    @Input() public addable = false;
    // Маленькая аватарка
    @Input() public small = false;
    // Параметр для отображения галочек согласователей
    @Input() public approvers = false;
    @Input() public approved = false;
    // Полное фио
    @Input() public fullName = false;
    // Нужна ли должность
    @Input() public position = null;
    @Input() public disabled = false;
    @Input() public searchStr = null;
    @Input() public responsible = null;
    // Должность кликабельна
    @Input() public positionActive = false;
    public userId = null;

    constructor(
        private config: ConfigBoardsService,
        private auth: AuthService
    ) {
    }

    get positionIsStr() {
        return typeof this.position === 'string';
    }

    getUserAvatar() {
        return this.config.getUserAvatarByUser(this.userSubdivision, 'small');
    }

    onUserClick(evt) {
        this.onUser.emit(this.userSubdivision);
    }

    onPositionClick() {
        this.positionClick.emit(true);
    }

    ngOnInit() {
        this.userId = this.auth.currentUser$.value.id;
    }
}
