import {JsonProperty, JsonType} from 'ta-json';

export class WorkdaySnapshot {
    @JsonProperty('count')
    @JsonType(Number)
    count: number = null;

    @JsonProperty('function_count')
    @JsonType(Number)
    function_count: number = null;

    @JsonProperty('project_time')
    @JsonType(Number)
    project_time: number = null;

    @JsonProperty('regular_time')
    @JsonType(Number)
    regular_time: number = null;
}
