import {AcademyUserRights} from 'src/app/models/user';
import {BehaviorSubject, Subject} from 'rxjs';
import {Department, DepartmentUser, UserTestRequest} from '../models/data-departments';
import {Injectable} from '@angular/core';
import {Question} from '../models/question';
import {TestAcademyClass} from '../models/test-academy';
import {TestInfo} from '../models/test-info';
import {TopicData} from '../models/topic';
import {UserTestAssignmentsByDepartment} from '../models/user-test-assignment';
import lodash from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AcademyService {
    /**
     * Права пользователя внутри модуля академии
     */
    currentUserRights$: BehaviorSubject<AcademyUserRights> =
        new BehaviorSubject<AcademyUserRights>({
            is_academy_administrator: false,
            is_company_administrator: false,
            is_manager: false,
            is_platform_owner_administrator: false,
            managed_departments_ids: [],
        });
    topics$: BehaviorSubject<TopicData[]> = new BehaviorSubject(null);
    tests$: BehaviorSubject<TestAcademyClass[]> = new BehaviorSubject([]);
    actualQuestions$: BehaviorSubject<Question[]> = new BehaviorSubject([]);
    currentUser$: BehaviorSubject<any> = new BehaviorSubject(null);
    allUsers$: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedUsers$: BehaviorSubject<UserTestAssignmentsByDepartment[]> =
        new BehaviorSubject([]);
    divisions$: BehaviorSubject<any> = new BehaviorSubject(null);
    topicsMaterialSubject = new BehaviorSubject<TopicData[]>([]);
    topicsMaterial$ = this.topicsMaterialSubject.asObservable();
    questions$: BehaviorSubject<any> = new BehaviorSubject(null);
    currentQuestionStatistics$ = new BehaviorSubject<any>([]);
    chosenTestInfoId$ = new BehaviorSubject<number>(null);
    chosenTestInfo$ = new BehaviorSubject<TestInfo>(null);
    isQuestionOpen$ = new BehaviorSubject<boolean>(false);
    departmentsStatistics$ = new BehaviorSubject<any>([]);
    counterOfNotViewedTests$ = new BehaviorSubject<any>(0);
    activePanelId: number | null = null;
    dataAboutDepartments$: BehaviorSubject<Department[]> = new BehaviorSubject([]);
    departmentUsers$:  BehaviorSubject<DepartmentUser[]> = new BehaviorSubject([]);
    usersForTest: UserTestRequest[] = []

    userStatistics$: BehaviorSubject<any> = new BehaviorSubject(null);
    userRating$: BehaviorSubject<any> = new BehaviorSubject(null)

    destroyed$: Subject<void> = new Subject();

    get isCurrentUserAdmin(): boolean {
        const rights = this.currentUserRights$.value;
        return rights.is_platform_owner_administrator;
    }

    // Полная проверка админ прав
    get isFullAdminRights(): boolean {
        const rights = this.currentUserRights$.getValue();
        return rights.is_academy_administrator ||
            rights.is_company_administrator ||
            rights.is_platform_owner_administrator;
    }

    setTopics(topics: TopicData[]) {
        this.topics$.next(topics);
    }

    setTopicsMaterial(topicsMaterial: TopicData[]): void {
        this.topicsMaterialSubject.next(topicsMaterial);
    }

    saveQuestion(question: Question): void {
        this.questions$.next(question);
    }

    setAssignmentsCounter(assignments: any[]) {
        this.counterOfNotViewedTests$.next(
            assignments.reduce((accumulator, currentValue) => {
                if (!currentValue.is_viewed && currentValue.questions_count) {
                    accumulator += 1;
                }
                return accumulator;
            }, 0),
        );
    }

    setActivePanel(id: number | null): void {
        this.activePanelId = id;
    }

    getActivePanel(): number | null {
        return this.activePanelId;
    }

    public filterUsersForTestRequest(list: DepartmentUser[], currentUsers: []): UserTestRequest[] {
      const localCurrentUsers = lodash.uniq(currentUsers, 'user_id').map((user) => user.user_id);

      const filterList = list.filter((user) => {
        if (!localCurrentUsers.includes(user.id)) {
          return true;
        } else {
          return false;
        }
      }).map((user) => {
        const userRequest: UserTestRequest = {
          department_id: user.department_id,
          user_id: user.id
        }
        return userRequest
      })
      return filterList
    }
}
