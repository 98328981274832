import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Application} from '../models/application';
import {Subdivision} from '../models/subdivision';
import {FunctionFull} from '../models/functions/function';
import {Position} from '../models/position';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    public openedFrdCard$ = new BehaviorSubject(null);
    public treeFunctionsByUser$ = new BehaviorSubject(null);
    public functions$ = new BehaviorSubject(null);

    public openedQuestionAnswers$ = new BehaviorSubject(false);
    public openedPosition$ = new BehaviorSubject<Position>(null);
    public openedPositionSatisfaction$ = new BehaviorSubject(false);
    public canExitPositionCard$ = new BehaviorSubject(true);
    public confimExitPositionCard$ = new Subject();

    public expandedSubdivisionsIds$ = new BehaviorSubject([]);
    public hoveredSlot$ = new BehaviorSubject<number>(null);
    public hoveredSubdivision$ = new BehaviorSubject<number>(null);
    public openedSubdivision$ = new BehaviorSubject<Subdivision>(null);
    public openedSubdivisionId$ = new BehaviorSubject<number>(null);
    public openedSubdivisionSatisfaction$ = new BehaviorSubject(false);
    public openedSlotClientsSatisfaction$ = new BehaviorSubject(false);
    public openedSlotMastersSatisfaction$ = new BehaviorSubject(false);
    public canExitSubdivisionCard$ = new BehaviorSubject(true);
    public confimExitSubdivisionCard$ = new Subject();
    public subdivisionSave$ = new Subject();
    public reloadSubdivision$ = new Subject();

    public openedSlot$ = new BehaviorSubject(null);
    public openedSlotId$ = new BehaviorSubject<number>(null);

    public openedFunctionId$ = new BehaviorSubject<number>(null);
    public openedFunction$ = new BehaviorSubject<FunctionFull>(null);
    public openedFunctionSatisfaction$ = new BehaviorSubject(false);
    public canExitFunctionCard$ = new BehaviorSubject(true);
    public confimExitFunctionCard$ = new Subject();
    public canExitQuestionCard$ = new BehaviorSubject(true);
    public confimExitQuestionCard$ = new Subject();

    public onCreateFunctionClick$ = new Subject();
    public onCreatePositionClick$ = new Subject();
    public slotChanged$ = new Subject();
    public deleteSlot$ = new Subject();
    public reloadList$ = new Subject();

    public companyApps$ = new BehaviorSubject<Application[]>(null);
    public subdivisions$ = new BehaviorSubject<Subdivision[]>(null);

    public searchTerm$ = new BehaviorSubject('');
    public isArchiveOn$ = new BehaviorSubject(false);
    public $settings = new BehaviorSubject(null);

    public isAdminSubdivisions$ = new BehaviorSubject(null);
    public isAdminPositions$ = new BehaviorSubject(null);
    public isAdminFunctions$ = new BehaviorSubject(null);
    public admins$ = new BehaviorSubject(null);

    public editMode$ = new BehaviorSubject(null);

    public canExit$ = new BehaviorSubject(true);
    public confimExit$ = new Subject();

    public collapsedMenuStruct$ = new BehaviorSubject(false);

    constructor() {
    }
}
