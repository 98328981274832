import {Component, EventEmitter, Input, Output} from '@angular/core';

import {User} from '../../../../../models/user';
import {AuthService} from '../../../../../services/auth.service';
import {ConfigService} from '../../../../../services/config.service';
import {Position} from '../../../../phonebook/models/position';
import {Subdivision} from '../../../../phonebook/models/subdivision';
import {Rating} from '../../../models/rating';

@Component({
    selector: 'app-slot',
    templateUrl: './slot.component.html',
    styleUrls: ['./slot.component.scss'],
})
export class SlotComponent {
    @Input() public searchStr = '';
    @Input() public disabled = true;
    @Input() public removeTooltip = null;
    @Input() public position: Position = null;
    @Input() public subdivision: Subdivision = null;
    @Input() public user: User = null;
    @Input() public rating: Rating = null;
    @Input() public isRole = false;
    @Input() public isPersonalInfo = false;
    @Output() public readonly remove: EventEmitter<any> = new EventEmitter();
    @Output() public readonly userSelection: EventEmitter<number> =
        new EventEmitter();

    constructor(
        private config: ConfigService,
        private auth: AuthService,
    ) {}

    get isNpm() {
        return this.auth.isNpm$.value;
    }
    ngOnInit() {}

    public onRemoveClick(evt) {
        evt.stopPropagation();
        this.remove.emit(true);
    }

    public getAvatar() {
        if (this.user) {
            return this.config.getUserAvatarByUser(this.user, 'small');
        } else {
            return this.config.photoAvatarUser + '/default/small';
        }
    }

    public selectUser(): void {
        this.userSelection.emit(this.user.id);
    }
}
