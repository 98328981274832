import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfigService} from '../../../../../services/config.service';
import {NavService} from '../../../services/nav.service';
import {CompanyService} from '../../../services/company.service';
import {takeUntil} from 'rxjs/operators';
import {Position} from '../../../models/position';
import {PositionsService} from '../../../services/positions.service';

@Component({
    selector: 'app-position-list-item',
    templateUrl: './position-list-item.component.html',
    styleUrls: ['./position-list-item.component.scss']
})
export class PositionListItemComponent implements  OnInit, OnDestroy {

    public position: Position = null;
    private destroyed = new Subject<void>();
    @Input() public searchStr = null;
    public isAdmin = false;
    @Input() public rating = true;
    @Input() public selectable = false;
    // Флаг для главного экрана должностей
    @Input() public main = false;

    constructor(
        private config: ConfigService,
        private nav: NavService,
        private company: CompanyService,
        private positionsService: PositionsService
    ) {
    }

    @Input() set data(params: Position) {
        this.position = params;

        this.position.slots = this.position.slots.filter(slot => {
            return !slot.struct.is_archive
        })
    }

    onPositionsClick() {
        if (!this.selectable) {
            this.nav.openPositionById(this.position.id);
        }
    }

    ngOnInit(): void {

        // this.company.searchTerm$
        //     .pipe(takeUntil(this.destroyed))
        //     .subscribe(res => {
        //         this.searchStr = res;
        //     });

        this.company.isAdminPositions$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.isAdmin = res;
            });
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}

