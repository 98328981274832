import {JsonElementType, JsonProperty, JsonType} from 'ta-json';
import {Rating} from '../../../shared/models/rating';

export class FunctionInfo {
    @JsonProperty('id')
    @JsonType(Number)
    id: number = null;

    @JsonProperty('title')
    @JsonType(String)
    title: string = null;

    @JsonProperty('rating')
    @JsonElementType(Rating)
    rating: Rating;

    @JsonProperty('weight')
    @JsonType(Number)
    weight: number;

    @JsonProperty('unit_id')
    @JsonType(Number)
    unitId: number;

    @JsonProperty('is_responsibility')
    @JsonType(Boolean)
    is_responsibility: boolean;

    @JsonProperty('path')
    @JsonType(Array)
    path = [];
}
